/**
 *------------------------------------------------------------------------------
 * @package       T3 Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/t3fw
 * @Link:         http://t3-framework.org
 *------------------------------------------------------------------------------
 */
jQuery (document).ready(function($){
    function getAndroidVersion(ua) {
        var ua = ua || navigator.userAgent;
        var match = ua.match(/Android\s([0-9\.]*)/);
        return match ? match[1] : false;
    };

    if (parseInt(getAndroidVersion()) == 4) {
        $('#t3-mainnav').addClass('t3-mainnav-android');
    }
    var JA_isLoading = false;
    // fix for old ie
    if (/MSIE\s([\d.]+)/.test(navigator.userAgent) ? new Number(RegExp.$1) < 10 : false) {
        $('html').addClass ('old-ie');
    } else if(/constructor/i.test(window.HTMLElement)){
        $('html').addClass('safari');
    }

    var $wrapper = $('body'),
        $inner = $('.t3-wrapper'),
        $toggles = $('.off-canvas-toggle'),
        $offcanvas = $('.t3-off-canvas'),
        $close = $('.t3-off-canvas .close'),
        $btn=null,
        $nav=null,
        direction = 'left',
        $fixed = null;
    // no wrapper, just exit
    if (!$wrapper.length) return ;

    // add effect class for nav
    $toggles.each (function () {
        var $this = $(this),
            $nav = $($this.data('nav')),
            effect = $this.data('effect'),
            direction = ($('html').attr('dir') === 'rtl' && $this.data('pos')!=='right') || ($('html').attr('dir') !== 'rtl' && $this.data('pos')==='right')  ? 'right':'left';

        $nav.addClass(effect).addClass('off-canvas-' + direction);

        // move to outside wrapper-content
        var inside_effect = ['off-canvas-effect-3','off-canvas-effect-16','off-canvas-effect-7','off-canvas-effect-8','off-canvas-effect-14'];
        if ($.inArray(effect, inside_effect) === -1) {
            $inner.before($nav);
        } else {
            $inner.prepend($nav);
        }
    });

    $toggles.on('tap', function(e){
        // detect direction

        stopBubble (e);

        if ($wrapper.hasClass ('off-canvas-open')) {
            oc_hide (e);
            return false;
        }

        $btn = $(this);
        $nav = $($btn.data('nav'));
        if (!$fixed) $fixed = $inner.find('*').filter (function() {return $(this).css("position") === 'fixed';});
        else $fixed = $fixed.filter (function() {return $(this).css("position") === 'fixed';}).add($inner.find('.affix'));

        $nav.addClass ('off-canvas-current');

        direction = ($('html').attr('dir') == 'rtl' && $btn.data('pos')!='right') || ($('html').attr('dir') != 'rtl' && $btn.data('pos')=='right')  ? 'right':'left';

        // add direction class to body
        // $('html').removeClass ('off-canvas-left off-canvas-right').addClass ('off-canvas-' + direction);

        $offcanvas.height($(window).height());

        // disable scroll event
        var events = $(window).data('events');
        if (events && events.scroll && events.scroll.length) {
          // store current handler for scroll
          var handlers = [];
          for (var i=0; i<events.scroll.length; i++){
            handlers[i] = events.scroll[i].handler;
          }
          $(window).data('scroll-events', handlers);
          $(window).off ('scroll');
        }
        // disable scroll on page
        var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
        $('html').addClass('noscroll').css('top',-scrollTop).data('top', scrollTop);
        //$('.t3-off-canvas').css('top',scrollTop);

        // make the fixed element become absolute
        $fixed.each (function () {
            var $this = $(this),
                $parent = $this.parent(),
                mtop = 0;
            // find none static parent
            while (!$parent.is($inner) && $parent.css("position") === 'static') $parent = $parent.parent();
            mtop = -$parent.offset().top;
            $this.css ({'position': 'absolute', 'margin-top': mtop});
        });

        $wrapper.scrollTop (scrollTop);
        // update effect class
        $wrapper[0].className = $.trim($wrapper[0].className.replace (/\s*off\-canvas\-effect\-\d+\s*/g, ' ')) +
            ' ' + $btn.data('effect') + ' ' + 'off-canvas-' + direction;

        setTimeout(oc_show, 50);

        return false;
    });
    var oc_show = function () {
        if (JA_isLoading === true) {
            return;
        }
        JA_isLoading=true;
        $wrapper.addClass ('off-canvas-open');
        $inner.on ('click', oc_hide);
        $close.on ('click', oc_hide);
        $offcanvas.on ('click', handleClick);

        // fix for old ie
        if ($.browser.msie && $.browser.version < 10) {
            var p1 = {}, p2 = {};
            p1['padding-'+direction] = $('.t3-off-canvas').width();
            p2[direction] = 0;
            $inner.animate (p1);
            $nav.animate (p2);
        }
        setTimeout (function (){JA_isLoading=false;}, 200);
    };

    var oc_hide = function () {
        if (JA_isLoading === true) {
            return;
        }
        JA_isLoading=true;

        //remove events
        $inner.off ('click', oc_hide);
        $close.off ('click', oc_hide);
        $offcanvas.off ('click', handleClick);

        //delay for click action
        setTimeout(function(){
            $wrapper.removeClass ('off-canvas-open');
        }, 100);

        setTimeout (function (){
            $wrapper.removeClass ($btn.data('effect')).removeClass ('off-canvas-'+direction);
            $wrapper.scrollTop (0);
            // enable scroll
            $('html').removeClass ('noscroll').css('top', '');
            $('html,body').scrollTop ($('html').data('top'));
            $nav.removeClass('off-canvas-current');
            $nav.removeAttr("style");
            // restore fixed elements
            $fixed.css ({'position': '', 'margin-top': ''});
            // re-enable scroll
            if ($(window).data('scroll-events')) {
              var handlers = $(window).data('scroll-events');
              for (var i=0; i<handlers.length; i++) {
                $(window).on ('scroll', handlers[i]);
              }
              $(window).data('scroll-events', null);
            }
            JA_isLoading=false;
        }, 700);

        // fix for old ie
        if ($('html').hasClass ('old-ie')) {
            var p1 = {}, p2 = {};
            p1['padding-'+direction] = 0;
            p2[direction] = -$('.t3-off-canvas').width();
            $inner.animate (p1);
            $nav.animate (p2);
        }

    };

    var handleClick = function (e) {
        if (e.target.tagName == 'A') {
            // handle the anchor link
            var arr1 = e.target.href.split('#'),
                arr2 = location.href.split('#');
            if (arr1[0] == arr2[0] && arr1.length > 1 && arr1[1].length) {
                oc_hide();
                setTimeout(function(){
                    var anchor = $("a[name='"+ arr1[1] +"']");
                    if (!anchor.length) anchor = $('#' + arr1[1]);
                    $('html,body').animate({scrollTop: anchor.offset().top},'slow');
                }, 500);
            }
        }
        stopBubble(e);
        return true;
    }

    var stopBubble = function (e) {
        e.stopPropagation();
    }

    // preload fixed items
    $(window).load(function() {
      setTimeout(function(){
        $fixed = $inner.find('*').filter (function() {return $(this).css("position") === 'fixed';});
      }, 100);
    });
})

/** 
 *------------------------------------------------------------------------------
 * @package       T3 Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github 
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/t3fw
 * @Link:         http://t3-framework.org 
 *------------------------------------------------------------------------------
 */

!function ($) {

  // legacy for $.browser to detect IE
  if ($.browser == undefined || $.browser.msie == undefined) {
    $.browser={msie:false,version:0};
    if (match = navigator.userAgent.match (/MSIE ([0-9]{1,}[\.0-9]{0,})/) || navigator.userAgent.match (/Trident.*rv:([0-9]{1,}[\.0-9]{0,})/)) {
      $.browser.msie=true;
      $.browser.version=match[1];
    }
  }
	// add ie version to html tag
  if ($.browser.msie) {
    $('html').addClass('ie'+ Math.floor($.browser.version));
  }

	// Detect grid-float-breakpoint value and put to $(body) data
	$(document).ready(function(){
			if (!window.getComputedStyle) {
					window.getComputedStyle = function(el, pseudo) {
							this.el = el;
							this.getPropertyValue = function(prop) {
									var re = /(\-([a-z]){1})/g;
									if (prop == 'float') prop = 'styleFloat';
									if (re.test(prop)) {
											prop = prop.replace(re, function () {
													return arguments[2].toUpperCase();
											});
									}
									return el.currentStyle[prop] ? el.currentStyle[prop] : null;
							}
							return this;
					}
			}
			var fromClass = 'body-data-holder',
					prop = 'content',
					$inspector = $('<div>').css('display', 'none').addClass(fromClass).appendTo($('body'));

			try {
				
				var computedStyle = window.getComputedStyle(
							$inspector[0], ':before'
					);
				if (computedStyle) {
					var attrs = computedStyle.getPropertyValue(prop);
					if(attrs){
							var matches = attrs.match(/([\da-z\-]+)/gi),
									data = {};
							if (matches && matches.length) {
									for (var i=0; i<matches.length; i++) {
											data[matches[i++]] = i<matches.length ? matches[i] : null;
									}
							}
							$('body').data (data);
					}
				}
			} finally {
					$inspector.remove(); // and remove from DOM
			}
	});
	
	
	//detect transform (https://github.com/cubiq/)
	(function(){
		$.support.t3transform = (function () {
			var style = document.createElement('div').style,
			vendors = ['t', 'webkitT', 'MozT', 'msT', 'OT'],
			transform, i = 0, l = vendors.length;

			for ( ; i < l; i++ ) {
				transform = vendors[i] + 'ransform';
				if ( transform in style ) {
					return transform;
				}
			}

			return false;
		})();

	})();
	
	//basic detect touch
	(function(){
		$('html').addClass('ontouchstart' in window ? 'touch' : 'no-touch');
	})();
	
	//document ready
	$(document).ready(function(){

		//remove conflict of mootools more show/hide function of element
		(function(){
			if(window.MooTools && window.MooTools.More && Element && Element.implement){

				var mthide = Element.prototype.hide,
					mtshow = Element.prototype.show,
					mtslide = Element.prototype.slide;

				Element.implement({
					show: function(args){
						if(arguments.callee &&
							arguments.callee.caller &&
							arguments.callee.caller.toString().indexOf('isPropagationStopped') !== -1){	//jquery mark
							return this;
						}

						return $.isFunction(mtshow) && mtshow.apply(this, args);
					},

					hide: function(){
						if(arguments.callee &&
							arguments.callee.caller &&
							arguments.callee.caller.toString().indexOf('isPropagationStopped') !== -1){	//jquery mark
							return this;
						}

						return $.isFunction(mthide) && mthide.apply(this, arguments);
					},

					slide: function(args){
						if(arguments.callee &&
							arguments.callee.caller &&
							arguments.callee.caller.toString().indexOf('isPropagationStopped') !== -1){	//jquery mark
							return this;
						}

						return $.isFunction(mtslide) && mtslide.apply(this, args);
					}
				})
			}
		})();

		// overwrite default tooltip/popover behavior (same as Joomla 3.1.5)
		$.fn.tooltip.Constructor && $.fn.tooltip.Constructor.DEFAULTS && ($.fn.tooltip.Constructor.DEFAULTS.html = true);
		$.fn.popover.Constructor && $.fn.popover.Constructor.DEFAULTS && ($.fn.popover.Constructor.DEFAULTS.html = true);
		$.fn.tooltip.defaults && ($.fn.tooltip.defaults.html = true);
		$.fn.popover.defaults && ($.fn.popover.defaults.html = true);

		//fix JomSocial navbar-collapse toggle
		(function(){
			if(window.jomsQuery && jomsQuery.fn.collapse){
			
				$('[data-toggle="collapse"]').on('click', function(e){
					
					//toggle manual
					$($(this).attr('data-target')).eq(0).collapse('toggle');
					
					//stop
					e.stopPropagation();

					return false;
				});

				//remove conflict on touch screen
				jomsQuery('html, body').off('touchstart.dropdown.data-api');
			}	
		})();


		//fix chosen select
		(function(){
			if($.fn.chosen && $(document.documentElement).attr('dir') == 'rtl'){
				$('select').addClass('chzn-rtl');
			}	
		})();

	});

	$(window).load(function(){
		//fix animation for navbar-collapse-fixed-top||bottom
		if(!$(document.documentElement).hasClass('off-canvas-ready') &&
			($('.navbar-collapse-fixed-top').length ||
			$('.navbar-collapse-fixed-bottom').length)){

			var btn = $('.btn-navbar[data-toggle="collapse"]');
			if (!btn.length){
				return;
			}

			if(btn.data('target')){
				var nav = $(btn.data('target'));
				if(!nav.length){
					return;
				}

				var fixedtop = nav.closest('.navbar-collapse-fixed-top').length;

				btn.on('click', function(){

					var wheight = (window.innerHeight || $(window).height());

					if(!$.support.transition){
						nav.parent().css('height', !btn.hasClass('collapsed') && btn.data('t3-clicked') ? '' : wheight);
						btn.data('t3-clicked', 1);
					}

					nav
						.addClass('animate')
						.css('max-height', wheight -
							(fixedtop ? (parseFloat(nav.css('top')) || 0) : (parseFloat(nav.css('bottom')) || 0)));
				});
				nav.on('shown hidden', function(){
					nav.removeClass('animate');
				});
			}
		}

	});

    // Equal block heights for the "default" view
	$(window).load(function () {
        var blocks = $('.panel-background.equal-heights, .equal-heights .itemList .col-md-4, .equal-heights .itemList .col-md-3, .dynamic-content__item.equal-heights');
	    var maxHeight = 0;
	    blocks.each(function () {
	        maxHeight = Math.max(maxHeight, parseInt($(this).css('height')));
	    });
	    blocks.css('height', maxHeight);
	});

    //Current year
    $(window).load(function () {
	    var currentYr = new Date().getFullYear();
	    jQuery("span.currentYear").html(currentYr);
    });

    //Off Canvas        
    $('.t3-off-canvas .nav-pills .dropdown-toggle').click(function () {
        $(this).toggleClass('active').attr('aria-expanded', function (i, attr) {
            return attr === 'true' ? 'false' : 'true';
        });
        $(this).parent().children('.dropdown-menu').toggleClass('active');
        return false;
    });

    // Main Menu: hover effects (menu.js deprecated)
    jQuery(document).ready(function ($) {
        $('.navbar-mainnav .dropdown').hover(function () {
            var self = $(this);
            var dropdown = $('.dropdown-toggle', self);

            hovertimerOpen = setTimeout(function () {
                self.addClass('open');
                dropdown.attr("aria-expanded", "true");
            }, 10);
        },
            function () {
                clearTimeout(hovertimerOpen);
                $('.navbar-mainnav .dropdown').removeClass('open');
                $('.dropdown-toggle').attr("aria-expanded", "false");
            }
        );
    });
}(jQuery);




jQuery(document).ready(function ($) {
    //// Search (main nav)
    function showSearch() {
        $('.search').click(function (e) {
            e.preventDefault();
            $('.search_container').toggleClass('show_search');
            $(".search_container .gsc-input").focus().attr('value', '');
        });
        $('.search-close').click(function () {
            $('.search_container').removeClass('show_search');
        });
    }

    $(".gsc-input").focus(function () {
        $(this).parent().addClass('is_focused');
    });

    $(".gsc-input").blur(function () {
        tmpval = $(this).val();
        if (tmpval === '') {
            $(this).parent().removeClass('is_focused');
        }
    });
    showSearch();

    //// Begin Cookie Alert: get & set
    $(".gsc-input").focus(function () {
        $(this).parent().addClass('is_focused');
    });

    $(".gsc-input").blur(function () {
        tmpval = $(this).val();
        if (tmpval === '') {
            $(this).parent().removeClass('is_focused');
        }
    });
    showSearch();

    var $alertBox = $('#cookie-policy'),
        cookieId = $alertBox.data("cookie-id"),
        cookieVal = $alertBox.data("cookie-value"),
        cookieExp = $alertBox.data("cookie-expiration");

    // if cookie-id-xxxxx does not exist, render alert box
    if (!Cookies.get(cookieId)) {
        $alertBox.removeClass('hide d-none');
    }

    var $optIn = $('#cookieOptin');

    // if verisk_Consent does not exist and the user is non-EU, drop the verisk_Consent cookie
    if (!Cookies.get("verisk_Consent") && $optIn.length === 0) {
        Cookies.set("verisk_Consent", cookieVal, { expires: cookieExp });
    }

    $('#acceptCookies').off('click.accept').on('click.accept', function (e) {
        e.preventDefault();
        $alertBox.addClass('hide d-none');
        Cookies.set(cookieId, cookieVal, { expires: cookieExp }); // set cookie based on data attributes
        if ($optIn !== null && $optIn.prop('checked') === true) {
            Cookies.set("verisk_Consent", cookieVal, { expires: cookieExp }); // set verisk_Consent cookie if EU user opts in
            location.reload();
        }
    });

    // if no consent is given, reset EPi_NumberOfVisits to 1
    if (!Cookies.get("verisk_Consent") && Cookies.get(cookieId)) {
        Cookies.set("EPi_NumberOfVisits", 1);
    }

    // if consent is given:
    if (Cookies.get("verisk_Consent")) {
        try {
            enableEloqua();
        } catch (err) {
            // User selected to not include Elequa script so enableEloqua function doesn't need to run
        }
    }

    function enableEloqua() {
        _elqQ.push(['elqSetSiteId', '1120']);
        _elqQ.push(['elqTrackPageView']);
        (function () {
            function async_load() {
                var s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = '//img.en25.com/i/elqCfg.min.js';
                var x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            }
            if (window.addEventListener) window.addEventListener('DOMContentLoaded', async_load, false);
            else if (window.attachEvent) window.attachEvent('onload', async_load);
        })();
    }
});
//// End Cookie Alert

// Login: if the user is logged in, retrieve the link necessary for them to log out
jQuery(document).ready(function ($) {
	$.ajax({
		url: '/api/veriskmultisite/user/getuserbanner',
		success: function (data) {
			if (data) {
				$('.custom_topmenu').prepend(data);
			}
		}
	});
});

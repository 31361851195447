var Search = (function($) {

    var autocompletion = function () {
        $(".gsc-input").autocomplete({
            source: function (request, response) {
                $.ajax({
                    dataType: "json",
                    type: 'GET',
                    url: "/api/verisk/autocomplete",
                    data: {
                        'substring': $(".gsc-input").val()
                    },
                    success: function (data) {
                        console.log(data);
                        response($.map(data, function (item) {
                            return {
                                label: item.query,
                                value: item.query
                            };
                        }));
                    },
                    error: function (data) {
                        console.log('autocomplete error!');
                    }
                });
            },
            select: function (event, ui) {
                // value is copied to input on select, set timeout, then trigger submit
                setTimeout(function () {
                    $("input.gsc-search-button").trigger('click');
                }, 1);
            },
            minLength: 2
        });
    };

    var sortBy = function () {
        $('form').find('#SelectedSortOrder').change(function () {
            $(this).parents('form').submit();
        });
    };

    var hideNoResultsLabel = function () {
        // if no results, hide sortBy filter
        if ($('.no-results-result').length) {
            $('.above-search-results').hide();
        }
    };
    
    return {
        autocompletion: autocompletion,
        sortBy: sortBy,
        hideNoResultsLabel: hideNoResultsLabel
    };

})(jQuery);

jQuery(function () {
    Search.autocompletion();
    Search.sortBy();
    Search.hideNoResultsLabel();
});
